.container{
    /* border: 2px solid ; */
    padding: 20px;
}
.label{
    padding-right: 10px;
}
.input-element{
    width: 100%;
    height: 25px;
    margin-top: 10px;
    border-radius: 5px;
}
.button{
   
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.button button{
    color: white;
    padding: 12px;   
    border-radius: 16px;
    border-style: none;
    cursor: pointer;
    background-color: #007bff;
    font-size: 16px;
}
.name-container{
    margin-bottom: 10px;
}

.submit-info-heading{
    font-size: 18px;
    color:darkgray;
}
.email-input {
    border: 1px solid #F1F5F9;
    padding: 10px;
    border-radius: 16px;
    font-size: 16px;
}

.email-input:hover {
    border: 1px solid #A0AEC0 !important;  /* Different color on hover */
}

.label{
    font-size: 18px;
    color:black;
    font-weight: 600;
}
