.chatbot {
  position: fixed;
  bottom: 60px;
  right: 20px;
  /* width: 400px !important; */
  /* height: 540px; */
  height: 90vh !important;
  width: 400px !important;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  font-style: normal;
  background-color: #ffffff !important;
}

/* Header styles */
.chat-header {
  background-color: #007bff !important;
  color: white !important;
  padding: 10px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.chat-header span {
  font-size: 18px;
  font-weight: bold;
}

.clear-button,
.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
}

.trash-icon {
  margin-bottom: 10px;
}

/* .close-button {
  margin-left: auto;
} */

/* Chat body styles */
.chat-body {
  flex-grow: 1;
  padding: 10px;
  background-color: #ffffff;
  overflow-y: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.chat-body::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for WebKit browsers */
}

.chat-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 2px;
}

/* Bot and user avatars */
.bot-avatar,
.user-avatar {
  width: 40px;
  height: 40px;
  background-color: transparent !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}

/* Message bubbles */
.message-text {
  display: flex;
  flex-direction: column;
  background-color: #f1f5f9;
  color: black;
  padding: 10px;
  /* border-radius: 8px; */
  /* max-width: 70%; */
  max-width: 100%;
  font-size: 16px !important;
  line-height: 1.4;
  font-style: normal;
  border-radius: 16px 24px 24px 4px;
}

.chat-message.user .message-text {
  /* background-color: #007bff; */
  /* color: white; */
  align-self: flex-end;
  order: 2;
  background-color: #ffffff;
  color: #202020;
  /* width: 158px;
  height: 52px; */
  border-radius: 24px 16px 4px 24px;
  border: 1px solid #e0e0e0;
  padding: 14px 16px;
  gap: 10px;
  display: flex;
  /* Use flexbox if the gap is between child elements */
  /* font-family: Manrope; */
}

.chat-message.user {
  justify-content: flex-end;
}

.timestamp {
  font-size: 10px;
  color: #999;
  align-self: flex-end;
  padding: 0 5px;
}

.message-container {
  display: flex;
  align-items: center;
}

/* Options */
.message-options {
  display: flex;
  margin-top: 5px;
}

.message-options button {
  display: block;
  width: 100%;
  margin-top: 5px;
  margin-right: 20px;
  background-color: #007bff;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 17px;
}

.option-button:disabled {
  pointer-events: none;
  /* Prevent hover */
  /* Add any other styles you want for the disabled state */
}

.message-options button:hover {
  background-color: #0056b3;
}

/* Typing indicator */
.chat-message.bot {
  color: #666;
  font-style: italic;
}

/* Footer input and send button */
.chat-footer {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #fff;
}

#user-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  padding-right: 35px;
  resize: none;
  height: 25px;
  overflow-y: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  font-family: unset;
}

#user-input::placeholder {
  color: #aaa;
  line-height: 25px;
  font-family: Poppins;
}

.send-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 20px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:hover {
  color: #0056b3;
}

.clear-button:hover {
  color: #ff4d4d;
}

.chatbot-header {
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope-SemiBold";
}

.option-item {
  color: var(--neutrals-600, #64748b);
}

.option-item:hover {
  background-color: #f0f2f5;
}

.option-text {
  color: var(--neutrals-600, #64748b);
  font-family: "Manrope-SemiBold" !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.options-icon {
  color: #070a13 !important;
  font-size: 20px;
  min-width: 20px !important;
  margin-right: 10px;
  cursor: pointer !important;
}

.dropdown-menu {
  border-radius: 16px !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  margin: 10px !important;
  width: 380px !important;
}

.bot-title {
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
}

.icon-container {
  background-color: #ffffff;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  padding: 2px !important;
  margin-top: 10px !important;
}

.logo-text-container {
  display: flex;
}

.caption-container {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  margin-bottom: 20px;
}

.caption {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #97a2b0 !important;
}

.bot-processing {
  margin-top: 4px;
  margin-left: 0px !important;
  display: flex;
  align-items: flex-end;
  color: rgb(8, 8, 8);
  /* Text color */
  /* background-color: #ddd; */
}

.bot-container {
  background-color: #F1F5F9;
  padding: 14px 16px 14px 16px;
  border-radius: 16px 24px 24px 4px;
}

.dot {
  display: inline-block;
  width: 6px !important;
  height: 6px !important;
  margin-left: 3px !important;
  background-color: #94a3b8 !important;
  /* Dot color */
  border-radius: 50% !important;
  animation: dot-bounce 1.4s infinite ease-in-out !important;
}

.dot:nth-child(2) {
  animation-delay: 0.2s !important;
}

.dot:nth-child(3) {
  animation-delay: 0.4s !important;
}

@keyframes dot-bounce {

  0%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-8px);
  }
}



@media only screen and (max-width: 600px) {
  .chatbot {
    bottom: 0px;
    right: 0px;
    width: 100% !important;
    height: 100vh !important;
    /* Fullscreen height */
    position: fixed;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    font-style: normal;
    background-color: #ffffff !important;
  }
}

/* Extra adjustments for very small screens (e.g., older iPhones) */
@media only screen and (max-width: 375px) {
  .chatbot {
    width: 100% !important;
    height: 100vh !important;
    position: fixed;
    bottom: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    font-style: normal;
    background-color: #ffffff !important;
  }

}