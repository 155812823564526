.chat-icon {
  position: fixed;
  bottom: 5px;
  right: 30px;
  width: 50px;
  height: 90px;
  /* background-color: #007bff; */
  /* color: white; */
  /* border-radius: 50%; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
}
